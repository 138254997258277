<template>
  <base-layout>
    <MarketPlaceAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.marketPlaceManagement.orders.headline')"
            @plus="add"
            :hide-plus="false"
          />
        </div>
      </div>

      <FSTable
        :fst-id="`marketplaceIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{
                    name: 'ViewMarketplaceProfile',
                    params: { id: item.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem class="flex items-center">
                  <img
                    :src="item.brand_image"
                    class="w-8 h-8 rounded-full pr-4"
                  />{{ item.brand_name }}
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.fleet.name"
                  :to="{
                    name: 'ViewFleetProfile',
                    params: { id: item.fleet.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem
                  :text="
                    getFormattedDateTime(item.order_start_date, 'D MMM, YYYY')
                  "
                />
                <FSTableRowItem>
                  {{ getFormattedDateTime(item.order_end_date, 'D MMM, YYYY') }}
                  (<TimeAgo
                    :title="item.order_end_date"
                    :datetime="item.order_end_date"
                    :auto-update="60"
                  />)
                </FSTableRowItem>
                <FSTableRowItem :text="item.total_vehicle_number" />
                <FSTableRowItem>
                  {{ item.fleet.country.currency_symbol }}
                  {{ item.total_order_value }}</FSTableRowItem
                >

                <FSTableRowItem :text="item.total_purchased_vehicle" />
                <FSTableRowItem>
                  {{ item.fleet.country.currency_symbol }}
                  {{ item.total_purchased_amount }}</FSTableRowItem
                >
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatusTitle(item.order_status)"
                    :variant="getItemVariant(item.order_status)"
                    profile="marketplace"
                  />
                </FSTableRowItem>
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" />
                    <MoreActions
                      :id="itemIndex"
                      :data="item"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    /></div
                ></FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.brand_name"
                  :to="{
                    name: 'ViewMarketplaceProfile',
                    params: { id: item.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem
                  :text="item.fleet.name"
                  :to="{
                    name: 'ViewFleetProfile',
                    params: { id: item.fleet.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatusTitle(item.order_status)"
                    :variant="getItemVariant(item.order_status)"
                    profile="marketplace"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.id'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.orderStartDate'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.order_start_date,
                          'D MMM, YYYY'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.orderEndDate'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(item.order_end_date, 'D MMM, YYYY')
                      }}
                      (<TimeAgo
                        :title="item.order_end_date"
                        :datetime="item.order_end_date"
                        :auto-update="60"
                      />)
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.totalVehicle'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.total_vehicle_number || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.totalOrder'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.fleet.country.currency_symbol }}
                      {{ item.total_order_value }}
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.totalPurchasedVehicle'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.total_purchased_vehicle || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.totalPurchaseAmount'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.fleet.country.currency_symbol }}
                      {{ item.total_purchased_amount }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center">
                        <oto-edit-icon @click="edit(item)" />
                        <MoreActions
                          :id="itemIndex"
                          :data="item"
                          @refresh="$store.dispatch('fsTable/fetchData')"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import MarketPlaceAddEdit from '@/views/marketplace/MarketPlaceAddEdit.vue'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
import MoreActions from '@/views/marketplace/shared/MoreActionsForMarketplace.vue'
export default {
  name: 'MarketplaceIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    MarketPlaceAddEdit,
    XStatus,
    MoreActions,
  },
  data() {
    return {
      indexDataEndpoint: MarketplaceConfig.api.index(),
      path: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.id'
          ),
          width: '5%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.brandName'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.assignedFleet'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.orderStartDate'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.orderEndDate'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.totalVehicle'
          ),
          width: '7%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.totalOrder'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.totalPurchasedVehicle'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.totalPurchaseAmount'
          ),
          width: '14%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.orderStatus'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.actions'
          ),
          width: '10%',
          sort: 'null',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.brandName'
          ),
          width: '35%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.assignedFleet'
          ),
          width: '35%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.table.columns.orderStatus'
          ),
          width: '20%',
          sort: 'null',
        },
      ],
      lockManufacturers: [],
      fleets: [],
    }
  },
  async created() {
    this.path = this.$route.path
    await this.$http
      .get(useEndpoints.manufacturers.index())
      .then((res) => {
        this.lockManufacturers = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })
      })
      .catch((e) => console.log(e))

    await this.$http
      .get(useEndpoints.dropdown.fleets())
      .then((res) => {
        this.fleets = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            country: item.country,
          }
        })
        console.log('fleets-', this.fleets)
      })
      .catch((e) => console.log(e))
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemStatusTitle(data) {
      if (data === 'Started') return 'In Progress'
      if (data === 'Pending') return 'Upcoming'
      return data
    },
    getItemVariant(data) {
      if (data === 'Completed') return 'gray'
      if (data === 'Expired') return 'red'
      if (data === 'Pending') return 'orange'
      return 'green'
    },
    add() {
      if (
        this.$store.getters['auth/organizationInfo'].subscription
          .marketplace_fleet_enabled
      ) {
        EventBus.$emit(MarketplaceConfig.events.editingData, {
          lockManufacturers: this.lockManufacturers,
          fleets: this.fleets,
          itemId: '',
        })
        dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Permission Denied`,
            text: 'You are not subscribed to access.Please contact with admin.',
          },
          7000
        )
      }
    },
    edit(item) {
      EventBus.$emit(MarketplaceConfig.events.editingData, {
        itemId: item.id,
        lockManufacturers: this.lockManufacturers,
        fleets: this.fleets,
      })
      dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
