<template>
  <base-layout>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" :z-index="20" />
    </div>
    <template v-if="$acl.canView('tasks')">
      <template v-if="isLoaded">
        <div>
          <section
            class="
        w-full
        px-4
        py-3
        bg-white
        rounded-md      
      "
          >
            <div class="flex justify-between items-center mb-2">
              <div class="font-semibold capitalize text-oDark text-xl">
                {{ taskDetails.title }}
              </div>
              <div>
                <template v-if="$acl.canUpdate('tasks')">
                  <AppButton
                    v-if="!getTaskStatusDisabled"
                    variant="secondary"
                    :text="
                      $t('components.taskManagement.details.action.editTask')
                    "
                    rounded="full"
                    @click="onEdgeStack"
                  />
                </template>
              </div>
            </div>
          </section>
          <section class="row bg-white">
            <div class="grid h-50 grid-cols-12">
              <div class="border-right px-2 col-span-12 md:col-span-6">
                <section>
                  <div class="grid grid-cols-3 mb-4">
                    <div class="pl-4 font-semibold text-14px text-oDark">
                      {{
                        $t(
                          'components.taskManagement.details.title.description'
                        )
                      }}
                    </div>
                    <div class="col-span-2 font-medium text-14px text-gray-500">
                      {{ taskDetails.description }}
                    </div>
                  </div>
                  <div class="grid grid-cols-3 mb-4">
                    <div class="pl-4 font-semibold text-14px text-oDark">
                      {{
                        $t('components.taskManagement.details.title.createdBy')
                      }}
                    </div>
                    <div
                      class="col-span-2 font-medium  text-14px text-gray-500"
                    >
                      <div class="flex gap-4">
                        <div class="w-10 h-10 bg-gray-300 rounded-full ">
                          <img
                            :src="`${taskDetails.created_by.profile_pic}`"
                            alt="avatar"
                            class="object-cover w-10 h-10 rounded-full px-1 py-3 text-xs"
                          />
                        </div>
                        <div>
                          <div>{{ taskDetails.created_by.full_name }}</div>
                          <div class="text-xs">
                            {{ taskDetails.created_by.organization_role }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 mb-4">
                    <div class="pl-4 font-semibold text-14px text-oDark">
                      {{ $t('components.taskManagement.details.title.tags') }}
                    </div>
                    <div class="col-span-2 font-medium text-14px text-gray-500">
                      <OtoTag
                        :entity-id="getVehicleId"
                        :entity-type="'bike'"
                        :editPermissionStatus="$acl.canNotUpdate('tasks')"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 mb-4">
                    <div class="pl-4 font-semibold text-14px text-oDark">
                      {{
                        $t(
                          'components.taskManagement.details.title.vehicleQRCode'
                        )
                      }}
                    </div>
                    <div class="col-span-2 font-medium text-14px text-gray-500">
                      {{ taskDetails.vehicle.qr_code }}
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-span-12 px-2 md:col-span-6">
                <section>
                  <div>
                    <div class="grid grid-cols-3 mb-4">
                      <div class="pl-4 font-semibold text-14px text-oDark">
                        {{
                          $t('components.taskManagement.details.title.status')
                        }}
                      </div>
                      <div class="col-span-2 flex items-center gap-2">
                        <div class="w-40">
                          <TaskAddEditStatusDropdown
                            v-model="taskStatus"
                            :options="taskStatusOptions"
                            :disabled="getTaskStatusDisabled"
                            :haveBorder="false"
                            :haveFill="true"
                            hideError
                            @change="onChangeTaskStatus"
                          />
                        </div>

                        <AppButton
                          variant="transparent"
                          height="24px"
                          text=""
                          rounded="sm"
                          :is-loading="taskStatusReq"
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-3 mb-4">
                      <div class="pl-4 font-semibold text-14px text-oDark">
                        {{
                          $t('components.taskManagement.details.title.priority')
                        }}
                      </div>
                      <div class="col-span-2 flex items-center gap-2">
                        <div class="w-40">
                          <TaskAddEditStatusDropdown
                            v-model="taskPriority"
                            :options="taskPriorityOptions"
                            :disabled="getTaskPriorityDisabled"
                            :haveBorder="false"
                            hideError
                            @change="onChangeTaskPriority"
                          />
                        </div>

                        <AppButton
                          variant="transparent"
                          height="24px"
                          text=""
                          rounded="sm"
                          :is-loading="taskPriorityReq"
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-3 mb-4">
                      <div class="pl-4 font-semibold text-14px text-oDark">
                        {{
                          $t(
                            'components.taskManagement.details.title.assignedTo'
                          )
                        }}
                      </div>
                      <div
                        class="col-span-2 font-medium text-14px text-gray-500"
                      >
                        <div class="flex gap-4">
                          <div class="w-10 h-10 bg-gray-300 rounded-full">
                            <img
                              :src="`${taskDetails.user.profile_pic}`"
                              alt="avatar"
                              class="object-cover w-10 h-10 rounded-full px-1 py-3 text-xs"
                            />
                          </div>
                          <div>
                            <div>{{ taskDetails.user.full_name }}</div>
                            <div class="text-xs">
                              {{ taskDetails.user.organization_role }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-3 mb-4">
                      <div class="pl-4 font-semibold text-14px text-oDark">
                        {{
                          $t(
                            'components.taskManagement.details.title.dateCreated'
                          )
                        }}
                      </div>
                      <div
                        class="col-span-2 font-medium text-14px text-gray-500"
                      >
                        <span
                          v-text="getUTCAwareTime(taskDetails.created_at)"
                          v-tooltip="
                            getUTCAwareTime(taskDetails.created_at, {
                              relative: true,
                            })
                          "
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-3 mb-4">
                      <div class="pl-4 font-semibold text-14px text-oDark">
                        {{
                          $t(
                            'components.taskManagement.details.title.lastUpdated'
                          )
                        }}
                      </div>
                      <div
                        class="col-span-2 font-medium text-14px text-gray-500"
                      >
                        <span
                          v-text="
                            getUTCAwareTime(taskDetails.updated_at, {
                              relative: true,
                            })
                          "
                          v-tooltip="getUTCAwareTime(taskDetails.updated_at)"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <tabs :items="navTabs" v-on:tabChange="onTabChange" />
            <div v-if="navTabs[0].isActive">
              <TaskLogs />
            </div>
            <div v-if="navTabs[1].isActive" class="px-10 py-4">
              <notes
                content-type="bike"
                :content-type-instance-id="getVehicleId"
                v-if="getVehicleId !== null && getVehicleId !== ''"
              />
            </div>
          </section>
          <TaskAddEdit
            :esId="addEdit.esId"
            :busy="addEdit.busy"
            :formData="addEdit.formData"
            :vehicleOptions="addEdit.vehicleOptions"
            :primaryKey="addEdit.primaryKey"
            @save="$store.dispatch('fsTable/fetchData')"
          />
        </div>
      </template>
    </template>
    <div v-else class="py-5 font-bold text-center text-gray-600">
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import OtoTag from '@/composites/tag/OtoTag.vue'
import { getUTCAwareTime } from '@/utils'
import { useEndpoints } from '@/composables'
import { httpClient } from '@/services'
import Tabs from '@/components/tabs/Tabs.vue'
import Notes from '@/composites/task/Notes.vue'
import TaskAddEdit from '@/composites/task/AddEdit.vue'
import TaskAddEditStatusDropdown from '@/composites/task/AddEditStatusDropdown.vue'
import {
  taskStatusOptions,
  taskPriorityOptions,
} from '@/composites/task/helpers.js'
import { useEdgeStack } from '@/plugins/edgeStack'
export default {
  name: 'ViewTaskDetails',
  components: {
    BaseLayout,
    OtoTag,
    TaskAddEditStatusDropdown,
    Tabs,
    Notes,
    TaskAddEdit,
    TaskLogs: () => import('@/views/task/TaskLogs.vue'),
  },
  data() {
    return {
      isLoaded: true,
      taskStatusReq: false,
      taskPriorityReq: false,
      taskDetails: {},
      taskStatusOptions: taskStatusOptions,
      taskPriorityOptions: taskPriorityOptions,
      navTabs: [
        {
          id: 0,
          title: this.$t('components.taskManagement.details.tabs.logs'),
          isActive: true,
        },
        {
          id: 1,
          title: this.$t('components.taskManagement.details.tabs.notes'),
          isActive: false,
        },
      ],
      addEdit: {
        esId: 'task-addEdit',
        busy: false,
        formData: null,
        primaryKey: null,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getVehicleId() {
      return this.taskDetails?.vehicle?.id || null
    },
    taskStatus: {
      get() {
        return this.taskDetails?.task_status ?? '--'
      },
      set(val) {
        this.taskDetails.task_status = val
      },
    },
    taskPriority: {
      get() {
        return this.taskDetails?.priority ?? '--'
      },
      set(val) {
        this.taskDetails.priority = val
      },
    },
    getTaskStatusDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.taskDetails?.task_status)
    },
    getTaskPriorityDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.taskDetails?.task_status)
    },
  },
  async created() {
    if (this.$acl.canView('tasks')) {
      await this.fetchTask()
    }
  },
  methods: {
    getUTCAwareTime,
    async onEdgeStack() {
      const edgeStack = useEdgeStack()

      this.addEdit.busy = true
      this.addEdit.primaryKey = this.id
      await this.fetchTaskDetails()
        .then((res) => {
          const data = res.data
          const vehicle = data.vehicle

          data.user = data.user.id
          data.due_by = this.$dayjs(data.due_by).format('YYYY-MM-DD')
          data.vehicle = vehicle.id

          this.addEdit.formData = {
            title: data.title,
            user: data.user,
            task_type: data.task_type,
            description: data.description,
            task_status: data.task_status,
            priority: data.priority,
            vehicle: data.vehicle,
            due_by: data.due_by,
          }

          this.addEdit.vehicleOptions = [
            {
              text: vehicle.qr_code,
              value: vehicle.id,
            },
          ]
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.addEdit.busy = false))

      edgeStack.open(this.addEdit.esId)
    },
    async fetchTask() {
      await this.fetchTaskDetails()
        .then((res) => {
          this.taskDetails = res.data
          console.log('task', res.data)
        })
        .catch((err) => {
          console.log({ err })
        })
        .finally(() => (this.isLoaded = true))
    },
    async fetchTaskDetails(primaryKey = this.id) {
      if (!primaryKey) {
        throw new Error('No primaryKey provided [fetchTask]')
      }
      const url = useEndpoints.task.details(primaryKey)
      return await this.$http.get(url)
    },
    async onChangeTaskStatus(newValue, oldValue) {
      this.taskStatusReq = true
      const url = useEndpoints.task.update(this.id)
      const data = new FormData()
      data.append('task_status', newValue)
      await httpClient
        .patch(url, data)
        .then((res) => {
          // console.log(res.data)
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Task Status Has Changed`,
            text: `The task is now in '${res.data.task_status}' state`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskStatus = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.detail ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.taskStatusReq = false))
    },
    async onChangeTaskPriority(newValue, oldValue) {
      this.taskPriorityReq = true
      const url = useEndpoints.task.update(this.id)
      const data = new FormData()
      data.append('priority', newValue)
      await httpClient
        .patch(url, data)
        .then((res) => {
          const priorities = {
            L: 'Low',
            M: 'Medium',
            H: 'High',
          }
          // console.log(res.data)
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Task Priority Has Changed`,
            text: `The task is now in '${priorities[res.data.priority] ??
              '--'}' priority`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskPriority = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.detail ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.taskPriorityReq = false))
    },
    onTabChange(tabId) {
      for (let i = 0; i < this.navTabs.length; ++i) {
        if (tabId !== i) {
          this.navTabs[i].isActive = false
        } else {
          this.navTabs[i].isActive = true
        }
      }
    },
  },
}
</script>
<style lang=""></style>
