<template>
  <div class="">
    <div class="wrapper wrapper-size-sm" :class="getWrapperClass">
      <div class="label-area">
        <slot name="labels">
          <div class="title">{{ title }}</div>
          <div class="subtitle">
            {{ subtitle }}
            <span
              v-if="haveSyncButton"
              @click="!isSyncButtonSpinning && $emit('sync')"
              ><i
                class="fas fa-rotate ml-1  "
                :class="
                  isSyncButtonSpinning
                    ? 'fa-spin cursor-not-allowed'
                    : 'cursor-pointer hover:text-black'
                "
              ></i
            ></span>
          </div>
        </slot>
      </div>
      <div class="bell-area" @click="handleAlarm()">
        <img
          v-if="type === 'RESTART'"
          class="bell"
          :class="getBellIconCLass"
          src="@/assets/icon/power.svg"
        />
        <img
          v-if="
            type === 'UNLOCK_BATTERY' ||
              type === 'UNLOCK_SADDLE' ||
              type === 'UNLOCK_TAILBOX' ||
              type === 'UNLOCK_SEAT' ||
              type === 'UNLOCK_HELMET' ||
              type === 'UNLOCK_CABLE_LOCK'
          "
          class="bell"
          :class="getBellIconCLass"
          src="@/assets/icon/battery_unlock.svg"
        />
        <img
          v-if="type === 'LOCK_BATTERY'"
          class="bell"
          :class="getBellIconCLass"
          src="@/assets/icon/lock-white.svg"
        />
        <img
          v-if="type === 'RING'"
          class="bell"
          :class="getBellIconCLass"
          src="@/assets/icon/bell-icon.svg"
        />
        <div class="mx-2 w-7 font-medium text-center text-14px text-oGray">
          {{ getBellTimerText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import InputLabel from "@/components/form/InputLabel";

export default {
  name: 'AlarmBox',
  components: {
    // InputLabel
  },
  props: [
    'title',
    'subtitle',
    'value',
    'size',
    'type',
    'haveSyncButton',
    'isSyncButtonSpinning',
  ],
  data() {
    return {
      model: this.value,
      timer: 0,
    }
  },
  computed: {
    getWrapperClass() {
      let sizes = ['base', 'md', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'

      let status = this.isTimerActive ? 'wrapper-on' : 'wrapper-off'

      return 'wrapper-size-' + size + ' ' + status
    },
    getBellTimerText() {
      return this.isTimerActive ? this.timer + 's' : 'Start'
    },
    getBellIconCLass() {
      return this.isTimerActive ? 'bell-on order-1' : 'bell-off'
    },
    isTimerActive() {
      return this.timer !== 0
    },
  },
  methods: {
    handleInput() {
      this.$emit('input')
    },
    handleChange() {
      this.$emit('change')
    },
    async handleAlarm(awaitFor = 10) {
      if (this.$acl.canUpdate('vehicles')) {
        if (!this.isTimerActive) {
          this.$emit('click')
          this.timer = awaitFor
          let countdown = await setInterval(() => {
            console.log(this.timer)
            if (this.timer === 0) {
              clearInterval(countdown)
              return
            }
            this.timer -= 1
          }, 1000)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 0.25rem;
  transition: all 0.5s ease-in-out;
  border-width: 1px;
  border-style: solid;

  &.wrapper-on {
    border-color: #fcad00;
    background-color: #fffbf1;
  }
  &.wrapper-off {
    border-color: #e2e8f0;
    background-color: #f7f7f7;
  }
  &.wrapper-size-base {
    height: 58px;
  }
  &.wrapper-size-md {
    height: 72px;
  }
  &.wrapper-size-lg {
    height: 110px;
  }
  // label area
  & .label-area {
    & .title {
      color: #2e2e39;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-size: 14px;
    }
    & .subtitle {
      padding-top: 4px;
      font-size: 14px;
      font-weight: normal;
      color: #9696a8;
    }
  }

  // bell area
  &.wrapper-on .bell-area {
    border: solid 1px #fcad00;
  }

  &.wrapper-off .bell-area {
    border: solid 1px #cacaca;
  }

  & .bell-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95px;
    height: 42px;
    padding: 5px 6px;
    border-radius: 21px;
    background-color: #ffffff;
    transition: all 1s ease-out;
    cursor: pointer;
    .bell {
      display: block;
      width: 33px;
      height: 33px;
      color: #fff;
      padding: 9px 8px 7px;
      border-radius: 22px;
      transform-origin: 50% 4px;
      &.bell-on {
        animation: ring 4s 0.1s ease-in-out infinite;
        background-color: #fcad00;
      }
      &.bell-off {
        animation: none;
        background-color: #a8a8a8;
      }
    }

    @-webkit-keyframes ring {
      0% {
        -webkit-transform: rotateZ(0);
      }
      1% {
        -webkit-transform: rotateZ(30deg);
      }
      3% {
        -webkit-transform: rotateZ(-28deg);
      }
      5% {
        -webkit-transform: rotateZ(34deg);
      }
      7% {
        -webkit-transform: rotateZ(-32deg);
      }
      9% {
        -webkit-transform: rotateZ(30deg);
      }
      11% {
        -webkit-transform: rotateZ(-28deg);
      }
      13% {
        -webkit-transform: rotateZ(26deg);
      }
      15% {
        -webkit-transform: rotateZ(-24deg);
      }
      17% {
        -webkit-transform: rotateZ(22deg);
      }
      19% {
        -webkit-transform: rotateZ(-20deg);
      }
      21% {
        -webkit-transform: rotateZ(18deg);
      }
      23% {
        -webkit-transform: rotateZ(-16deg);
      }
      25% {
        -webkit-transform: rotateZ(14deg);
      }
      27% {
        -webkit-transform: rotateZ(-12deg);
      }
      29% {
        -webkit-transform: rotateZ(10deg);
      }
      31% {
        -webkit-transform: rotateZ(-8deg);
      }
      33% {
        -webkit-transform: rotateZ(6deg);
      }
      35% {
        -webkit-transform: rotateZ(-4deg);
      }
      37% {
        -webkit-transform: rotateZ(2deg);
      }
      39% {
        -webkit-transform: rotateZ(-1deg);
      }
      41% {
        -webkit-transform: rotateZ(1deg);
      }

      43% {
        -webkit-transform: rotateZ(0);
      }
      100% {
        -webkit-transform: rotateZ(0);
      }
    }

    @-moz-keyframes ring {
      0% {
        -moz-transform: rotate(0);
      }
      1% {
        -moz-transform: rotate(30deg);
      }
      3% {
        -moz-transform: rotate(-28deg);
      }
      5% {
        -moz-transform: rotate(34deg);
      }
      7% {
        -moz-transform: rotate(-32deg);
      }
      9% {
        -moz-transform: rotate(30deg);
      }
      11% {
        -moz-transform: rotate(-28deg);
      }
      13% {
        -moz-transform: rotate(26deg);
      }
      15% {
        -moz-transform: rotate(-24deg);
      }
      17% {
        -moz-transform: rotate(22deg);
      }
      19% {
        -moz-transform: rotate(-20deg);
      }
      21% {
        -moz-transform: rotate(18deg);
      }
      23% {
        -moz-transform: rotate(-16deg);
      }
      25% {
        -moz-transform: rotate(14deg);
      }
      27% {
        -moz-transform: rotate(-12deg);
      }
      29% {
        -moz-transform: rotate(10deg);
      }
      31% {
        -moz-transform: rotate(-8deg);
      }
      33% {
        -moz-transform: rotate(6deg);
      }
      35% {
        -moz-transform: rotate(-4deg);
      }
      37% {
        -moz-transform: rotate(2deg);
      }
      39% {
        -moz-transform: rotate(-1deg);
      }
      41% {
        -moz-transform: rotate(1deg);
      }

      43% {
        -moz-transform: rotate(0);
      }
      100% {
        -moz-transform: rotate(0);
      }
    }

    @keyframes ring {
      0% {
        transform: rotate(0);
      }
      1% {
        transform: rotate(30deg);
      }
      3% {
        transform: rotate(-28deg);
      }
      5% {
        transform: rotate(34deg);
      }
      7% {
        transform: rotate(-32deg);
      }
      9% {
        transform: rotate(30deg);
      }
      11% {
        transform: rotate(-28deg);
      }
      13% {
        transform: rotate(26deg);
      }
      15% {
        transform: rotate(-24deg);
      }
      17% {
        transform: rotate(22deg);
      }
      19% {
        transform: rotate(-20deg);
      }
      21% {
        transform: rotate(18deg);
      }
      23% {
        transform: rotate(-16deg);
      }
      25% {
        transform: rotate(14deg);
      }
      27% {
        transform: rotate(-12deg);
      }
      29% {
        transform: rotate(10deg);
      }
      31% {
        transform: rotate(-8deg);
      }
      33% {
        transform: rotate(6deg);
      }
      35% {
        transform: rotate(-4deg);
      }
      37% {
        transform: rotate(2deg);
      }
      39% {
        transform: rotate(-1deg);
      }
      41% {
        transform: rotate(1deg);
      }

      43% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(0);
      }
    }
  }
}
</style>
