var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EdgeStack',{attrs:{"id":_vm.esId,"busy":_vm.busy,"exitButton":{ text: 'Cancel', hidden: false },"size-class":"w-full md:w-2/5"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between -mt-8"},[_c('div',{staticClass:"panel-title"},[_vm._v(_vm._s(_vm.actionText))])])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isShowBulkTaskReport)?_c('AppButton',{attrs:{"variant":"primary","text":_vm.actionText,"is-loading":_vm.isReq},on:{"click":function($event){return _vm.$refs.submitButton.click()}}}):_c('div',[_c('AppButton',{attrs:{"variant":"primary","text":"Export"},on:{"click":_vm.handleBulkTaskReportExport}})],1)]},proxy:true},{key:"default",fn:function(){return [(!_vm.isShowBulkTaskReport)?[_c('ValidationObserver',{ref:"observer",staticClass:"px-4",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('AppInput',{attrs:{"type":"text","name":_vm.$t('components.taskManagement.addEdit.steps.profile.title.title'),"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.title'),"rules":"required","placeholder":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.placeholder.taskTitle'
            )},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.assignTo'
            ),"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.assignTo'
            ),"rules":"required","placeholder":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.placeholder.selectEmployee'
            ),"text-attribute":"text","value-attribute":"value","options":_vm.users},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t('components.taskManagement.addEdit.steps.profile.title.type'),"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.type'),"rules":"required","placeholder":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.placeholder.selectAType'
            ),"options":[
            {
              value: 'CHARGING',
              text: _vm.$t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.charge'
              ),
            },
            {
              value: 'REBALANCING',
              text: _vm.$t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.rebalance'
              ),
            },
            {
              value: 'SWAP_BATTERY',
              text: _vm.$t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.swapBattery'
              ),
            },
            {
              value: 'MAINTENANCE',
              text: _vm.$t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.maintenance'
              ),
            } ],"hide-search-box":""},model:{value:(_vm.form.task_type),callback:function ($$v) {_vm.$set(_vm.form, "task_type", $$v)},expression:"form.task_type"}}),_c('AppInput',{attrs:{"type":"textarea","name":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.description'
            ),"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.description'
            ),"rules":"","placeholder":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.placeholder.writeDescription'
            )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('TaskAddEditStatusDropdown',{attrs:{"label":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.status'
              ),"options":_vm.taskStatusOptions,"disabled":_vm.getTaskStatusDisabled},model:{value:(_vm.form.task_status),callback:function ($$v) {_vm.$set(_vm.form, "task_status", $$v)},expression:"form.task_status"}}),_c('TaskAddEditStatusDropdown',{attrs:{"label":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.priority'
              ),"options":_vm.taskPriorityOptions,"disabled":_vm.getTaskPriorityDisabled},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}})],1),_c('AppInput',{attrs:{"type":"date","name":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.dueDate'
            ),"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.dueDate'
            ),"rules":"required"},model:{value:(_vm.form.due_by),callback:function ($$v) {_vm.$set(_vm.form, "due_by", $$v)},expression:"form.due_by"}}),(!_vm.isExternalTaskCreation)?[(_vm.isEditing)?_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              ),"label":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              ),"rules":"required","placeholder":"e.g. 10001001","text-attribute":"text","value-attribute":"value","disabled":true,"options":_vm.vehicleOptions},model:{value:(_vm.form.vehicle),callback:function ($$v) {_vm.$set(_vm.form, "vehicle", $$v)},expression:"form.vehicle"}}):_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              ),"label":_vm.$t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              ),"rules":"required","placeholder":"e.g. 10001001","text-attribute":"text","value-attribute":"value","multiple":"","options":_vm.vehicleOptions,"fetch-options":_vm.onFetchVehicles},model:{value:(_vm.form.vehicle),callback:function ($$v) {_vm.$set(_vm.form, "vehicle", $$v)},expression:"form.vehicle"}})]:_vm._e(),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"},on:{"click":function($event){return handleSubmit(_vm.onSave)}}},[_vm._v(" Save ")])]}}],null,false,3909386872)})]:[_c('div',{key:"task-bulk-report",staticClass:" mt-3 pt-5 border-oLightestGray border-t-2"},[_c('STable',{attrs:{"sId":'task-bulk-report',"headers":_vm.taskBulkReportHeaders}},[_vm._l((_vm.taskBulkReports),function(taskReportItem,taskReportItemIndex){return [_c('STableRow',{key:taskReportItemIndex,attrs:{"text-fallback-always":""}},[_c('STableRowItem',{attrs:{"to":{
                  name: 'ViewVehicleProfile',
                  params: { id: taskReportItem.id },
                },"target":"_blank","text":taskReportItem.qr_code}}),_c('STableRowItem',[(taskReportItem.success)?[_c('i',{staticClass:" text-green-500 fas fa-check-circle"})]:[_c('i',{staticClass:" text-oRed fas fa-exclamation-circle"})]],2),_c('STableRowItem',{attrs:{"text":taskReportItem.failure_reason}})],1)]})],2)],1)]]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }