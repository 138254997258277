var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',{},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.taskManagement.headline'),"hide-plus":_vm.$acl.canNotCreate('tasks')},on:{"plus":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, esAction: 'open' })}}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)]),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.taskManagement.summary.total'),"value":_vm.indexMetaData.summary.total,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.taskManagement.summary.ongoing'),"value":_vm.indexMetaData.summary.ongoing,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.taskManagement.summary.rebalancing'),"value":_vm.indexMetaData.summary.rebalancing,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.taskManagement.summary.charging'),"value":_vm.indexMetaData.summary.charging,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.taskManagement.summary.completed'),"value":_vm.indexMetaData.summary.done,"variant":"gray"}})],1)])]),_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.getTableHeadersTitle,"exportType":_vm.exportKey.OPERATOR_TASK,"exportFromURL":true,"endpoint":_vm.endpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{ name: 'ViewTaskDetails', params: { id: item.id } }}}),_c('FSTableRowItem',{attrs:{"text":item.title},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-blue-600 cursor-pointer",domProps:{"textContent":_vm._s(text)},on:{"click":function($event){return _vm.onEdgeStack({ esId: _vm.details.esId, primaryKey: item.id })}}})]}}],null,true)}),_c('FSTableRowItem',[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.formatTaskType(item.task_type))+" ")])]),(!_vm.hideColumns.vehicle)?_c('FSTableRowItem',{attrs:{"to":{
                  name: 'ViewVehicleProfile',
                  params: { id: item.vehicle.id },
                },"text":item.vehicle.qr_code,"target":"_blank"}}):_vm._e(),_c('FSTableRowItem',{attrs:{"text":item.created_at,"date":""}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.getDate(item.due_by))+" ")]),_c('FSTableRowItem',[_c('div',{staticClass:"flex flex-col"},[_c('RouterLink',{staticClass:"text-blue-600",attrs:{"to":{
                      name: 'ViewOrganizationUserProfile',
                      params: { id: item.user.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]),_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v(" ("+_vm._s(item.user.organization_role)+") ")])],1)]),_c('FSTableRowItem',{attrs:{"text":item.task_status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var text = ref.text;
return [_c('TaskListStatusDropdown',{attrs:{"primaryKey":item.id,"taskStatus":text}})]}}],null,true)}),_c('FSTableRowItem',[_c('div',{staticClass:"flex justify-start gap-2"},[(_vm.getEditStatus(item.task_status))?_c('OtoEditIcon',{on:{"click":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: item.id })}}}):_vm._e(),_c('TaskMoreActionsDropdown',{key:item.id,attrs:{"data":item},on:{"delete:success":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{ name: 'ViewTaskDetails', params: { id: item.id } }}}),_c('FSTableRowItem',[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.formatTaskType(item.task_type))+" ")])]),_c('FSTableRowItem',{attrs:{"text":item.task_status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var text = ref.text;
return [_c('TaskListStatusDropdown',{attrs:{"primaryKey":item.id,"taskStatus":text}})]}}],null,true)})],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.title'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('span',{staticClass:"text-blue-600 cursor-pointer",domProps:{"textContent":_vm._s(item.title)},on:{"click":function($event){return _vm.onEdgeStack({
                          esId: _vm.details.esId,
                          primaryKey: item.id,
                        })}}})])]),(!_vm.hideColumns.vehicle)?_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.vehicleQR'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('RouterLink',{staticClass:"text-blue-600",attrs:{"to":{
                        name: 'ViewVehicleProfile',
                        params: { id: item.vehicle.id },
                      },"target":"_blank"}},[_vm._v(" "+_vm._s(item.vehicle.qr_code)+" ")])],1)]):_vm._e(),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.createdAt'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getUTCAwareTime(item.created_at) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.dueDate'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getDate(item.due_by) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.assignedTo'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex flex-col"},[_c('RouterLink',{staticClass:"text-blue-600",attrs:{"to":{
                          name: 'ViewOrganizationUserProfile',
                          params: { id: item.user.id },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]),_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v(" ("+_vm._s(item.user.organization_role)+") ")])],1)])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.taskManagement.table.columns.actions'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex justify-start gap-2"},[(_vm.getEditStatus(item.task_status))?_c('OtoEditIcon',{on:{"click":function($event){return _vm.onEdgeStack({
                            esId: _vm.addEdit.esId,
                            primaryKey: item.id,
                          })}}}):_vm._e(),_c('TaskMoreActionsDropdown',{key:item.id,attrs:{"data":item},on:{"delete:success":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])])])]):_vm._e()]:_vm._e()]})]}}])})],1),_c('TaskDetails',{attrs:{"esId":_vm.details.esId,"busy":_vm.details.busy,"rawData":_vm.details.rawData,"primaryKey":_vm.details.primaryKey},on:{"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: $event.taskId })}}}),(_vm.$acl.canCreate('tasks'))?_c('TaskAddEdit',{attrs:{"esId":_vm.addEdit.esId,"busy":_vm.addEdit.busy,"formData":_vm.addEdit.formData,"vehicleOptions":_vm.addEdit.vehicleOptions,"primaryKey":_vm.addEdit.primaryKey},on:{"save":function($event){return _vm.$store.dispatch('fsTable/fetchData')},"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.details.esId })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }