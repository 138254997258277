export const RfidAssignRiderConfig = {
  api: {
    //post
    create: () => `/dashboard/user-rfid/`,
    //patch
    update: (id) => `/dashboard/user-rfid/${id}/`,
    //get
    details: (id) => `/dashboard/user-rfid/${id}/`,
    //delete
    delete: (id) => `/dashboard/user-rfid/${id}/`,
  },
  events: {
    name: 'rfid-assign-rider',
    // refresh-index-data
    refresh: `rid-rfid-assign-rider`,
    // slideover-right
    sorId: 'rfid-assign-rider',

    sorOpen: (id) => `sor-open-rfid-assign-rider-${id}`,
    sorClose: (id) => `sor-close-rfid-assign-rider-${id}`,
    sorToggle: (id) => `sor-toggle-rfid-assign-rider-${id}`,
    // editing-data
    editingData: 'edit-rfid-assign-rider-data',
    // viewing-data
    viewingData: 'view-rfid-assign-rider-data',
  },
}
