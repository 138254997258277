import { render, staticRenderFns } from "./TakeActions.vue?vue&type=template&id=bc804884&scoped=true&"
import script from "./TakeActions.vue?vue&type=script&lang=js&"
export * from "./TakeActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc804884",
  null
  
)

export default component.exports