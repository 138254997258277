var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('section',{staticClass:"login-container"},[_c('div',{staticClass:"flex flex-col justify-center items-center"},[[(_vm.isSkootel)?_c('img',{attrs:{"src":require("@/assets/otoride/logo/brand/skootel.png"),"alt":"Skootel Logo"}}):(_vm.isBreeze)?_c('img',{attrs:{"src":require("@/assets/otoride/logo/brand/breeze.png"),"alt":"Breeze Logo"}}):_c('img',{attrs:{"src":require("@/assets/otoride/logo/otoride-logo-v2-1x.png"),"alt":"OTORide Logo"}})],_c('div',{staticClass:"mt-5 login-area"},[_c('div',{staticClass:"px-12 my-5 w-full"},[_c('h1',{staticClass:"mt-16 mb-10 text-lg font-bold sm:text-xl md:text-2xl",staticStyle:{"color":"#505458"}},[_vm._v(" Reset Your Password ")]),(_vm.message)?_c('div',{staticClass:"h-6  mb-5",class:{
              'text-red-500': _vm.isRequestFailed,
              'text-green-500': !_vm.isRequestFailed,
              'mb-20': _vm.isResetComplete,
            }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',[_vm._v(" "+_vm._s(_vm.message)+" ")])])],1):_vm._e(),(!_vm.isResetComplete)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"Enter New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('password-field',{attrs:{"name":"Enter New Password","placeholder":"Enter Password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('password-field',{attrs:{"name":"Confirm New Password","placeholder":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('div',{staticClass:"pb-6 mt-6"},[_c('t-button',{staticClass:"w-full",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)],1)]}}],null,false,2749409578)}):_vm._e()],1)])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }