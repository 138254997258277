<template>
  <slide-over-right
    :id="`${sorId}-${id}`"
    v-on:showModal="showModal"
    editType="standard"
  >
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        {{ getTitleText }}
      </h2>
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <div class="flex items-center gap-2">
          <AppInput
            v-model="form.rfid_number"
            type="text"
            rules="required"
            :name="$t('components.riderManagement.modal.rfid.title.rfidNumber')"
            :label="
              $t('components.riderManagement.modal.rfid.title.rfidNumber')
            "
            infoDescription=""
          />
        </div>

        <div class="flex justify-end items-center mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary" @click="closeModal">{{
              $t('components.stepNavigation.cancel')
            }}</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" class="ml-3">{{
            isEditing
              ? $t('components.stepNavigation.update')
              : $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { RfidAssignRiderConfig } from '@/config/RfidAssignRiderConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

export default {
  name: 'AddEditRfidToRider',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    riderData: {
      type: Object,
      required: true,
    },
  },
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: RfidAssignRiderConfig.events.sorId,
    }
  },
  computed: {
    isEditing: function() {
      return Object.keys(this.item).length !== 0
    },
    getTitleText() {
      return this.isEditing
        ? this.$t('components.riderManagement.modal.rfid.headline.update')
        : this.$t('components.riderManagement.modal.rfid.headline.add')
    },
  },
  mounted() {
    EventBus.$on(RfidAssignRiderConfig.events.editingData, (item) => {
      this.item = item
      this.form = { ...this.item }
    })
    console.log({ s: this.isEditing })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(RfidAssignRiderConfig.events.sorToggle(this.id)))
    },
    closeModal() {
      dispatchEvent(new Event(RfidAssignRiderConfig.events.sorClose(this.id)))
    },
    submit: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? RfidAssignRiderConfig.api.update(this.item.id)
        : RfidAssignRiderConfig.api.create()
      let data = new xMan(this.form).toFormData()
      data.append('user', this.riderData.id)

      let message = this.isEditing
        ? 'RFID updated successfully'
        : 'RFID added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(RfidAssignRiderConfig.events.sorClose(this.id)))
        // Refetch the indexData
        dispatchEvent(new Event(RfidAssignRiderConfig.events.refresh))

        this.$emit('refresh')
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        // todo: remove logs
        console.log('rfid-err', { error })
        // todo: show server errors
        this.isLoading = false

        this.$notify(
          {
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: `${error.response.data.message}`,
          },
          5000
        )
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>

<style></style>
