<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col justify-center items-center">
        <template>
          <img
            v-if="isSkootel"
            src="@/assets/otoride/logo/brand/skootel.png"
            alt="Skootel Logo"
          />
          <img
            v-else-if="isBreeze"
            src="@/assets/otoride/logo/brand/breeze.png"
            alt="Breeze Logo"
          />
          <img
            v-else
            src="@/assets/otoride/logo/otoride-logo-v2-1x.png"
            alt="OTORide Logo"
          />
        </template>
        <div class="mt-5 login-area">
          <div class="px-12 my-5 w-full">
            <h1
              class="mt-16 mb-10 text-lg font-bold sm:text-xl md:text-2xl"
              style="color: #505458"
            >
              Reset Your Password
            </h1>
            <div
              v-if="message"
              class="h-6  mb-5"
              :class="{
                'text-red-500': isRequestFailed,
                'text-green-500': !isRequestFailed,
                'mb-20': isResetComplete,
              }"
            >
              <transition name="fade">
                <div>
                  {{ message }}
                </div>
              </transition>
            </div>
            <ValidationObserver
              v-slot="{ handleSubmit }"
              v-if="!isResetComplete"
            >
              <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                  name="Enter New Password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field
                    name="Enter New Password"
                    placeholder="Enter Password"
                    v-model="newPassword"
                  />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
                <ValidationProvider
                  name="Confirm New Password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field
                    name="Confirm New Password"
                    placeholder="Confirm Password"
                    v-model="confirmPassword"
                  />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
                <div class="pb-6 mt-6">
                  <t-button class="w-full" type="submit">
                    Save
                  </t-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- end:component -->
    </section>
  </div>
</template>

<script>
import PasswordField from '@/components/form/PasswordField'
import { xMan } from '@/utils'
export default {
  components: {
    PasswordField,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      isLoading: false,
      isRequestFailed: false,
      message: '',
      isResetComplete: false,
    }
  },
  computed: {
    isSkootel() {
      return window.location.host.split('.').includes('skootel')
    },
    isBreeze() {
      return window.location.host.split('-').includes('breeze')
    },
  },
  methods: {
    async submit() {
      this.isLoading = true
      this.isRequestFailed = false
      this.message = ''
      const payload = {
        new_password: this.newPassword,
        confirm_new_password: this.confirmPassword,
        token: this.$route.query.token,
      }
      const formData = new xMan(payload).toFormData()
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}dashboard/org-users/${this.$route.query.user}/set-password/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            body: formData,
          }
        )
        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(`${errorData.message}`)
        }
        const data = await response.json()

        this.message = data.message
        this.isResetComplete = true
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      } catch (error) {
        console.log('test-error-1', { error })
        this.isRequestFailed = true
        this.message = error.message
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// todo: @use "sass:math";

$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 50%;
  left: 50%;
  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  border-radius: 0.375rem;
  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}
</style>
