<template>
  <slide-over-right
    :id="sorId"
    v-on:showModal="showModal"
    :fullWidth="true"
    editType="charging-dock-port"
  >
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <div class="flex gap-2">
        <slide-over-right-trigger :id="sorId">
          <div
            class="items-center justify-center mr-4 cursor-pointer back-button"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
        </slide-over-right-trigger>
        <div>
          <div class="font-semibold text-19px text-oCharcoal flex">
            {{ $t('components.chargingDockPort.table.headline.id') }}:
            <span> {{ dockData.kuhmute_hub_uuid }} </span>
          </div>
          <div>
            <span>
              {{ $t('components.chargingDockPort.table.headline.name') }}:
              {{ dockData.name || '--' }},
              {{
                $t('components.chargingDockPort.table.headline.serialNumber')
              }}: {{ dockData.id || '--' }}</span
            >
          </div>
        </div>
      </div>
    </template>
    <div
      :key="`chargingDockPortIndex_${counter}`"
      class=" mt-3 pt-5 border-oLightestGray border-t-2"
    >
      <STable :sId="'charging-dock-port-list'" :headers="getTableHeaders">
        <template
          v-for="(chargingDockPortItem,
          chargingDockPortItemIndex) in chargingDockPortList"
        >
          <template v-if="getTableMode === 'full'">
            <STableRow :key="chargingDockPortItemIndex" text-fallback-always>
              <STableRowItem :text="chargingDockPortItem.number" />
              <STableRowItem
                :text="
                  getFormattedDateTime(
                    chargingDockPortItem.updated,
                    'D MMM YYYY, h:mm:ss a'
                  )
                "
              />
              <STableRowItem>
                <router-link
                  v-if="chargingDockPortItem.bike"
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: chargingDockPortItem.bike.id },
                  }"
                  :class="`text-blue-600 mr-5`"
                  target="_blank"
                >
                  {{ chargingDockPortItem.bike.qr_code }}
                </router-link>
                <span v-else>--</span>
              </STableRowItem>

              <STableRowItem
                :text="
                  chargingDockPortItem.bike
                    ? chargingDockPortItem.bike.lock.lock_id
                    : '--'
                "
              />
              <STableRowItem
                :text="
                  chargingDockPortItem.bike
                    ? chargingDockPortItem.bike.charging_adapter
                      ? chargingDockPortItem.bike.charging_adapter.adapter_code
                      : '--'
                    : '--'
                "
              />
              <STableRowItem>
                <x-status
                  :variant="getPortStatusVariant(chargingDockPortItem.status)"
                  :text="getPortStatusText(chargingDockPortItem.status)"
                  profile="vehicle"
                />
              </STableRowItem>
            </STableRow>
          </template>
          <template v-if="getTableMode === 'responsive'">
            <STableRow :key="`s-table-row-${chargingDockPortItemIndex}`">
              <STableRowItem>
                <div
                  class="col-span-1 focus:text-gray-400"
                  @click="toggle(chargingDockPortItemIndex)"
                >
                  <i
                    class="fas fa-minus-circle"
                    style="color:#d90a20;"
                    v-if="opened.includes(chargingDockPortItemIndex)"
                  ></i>
                  <i class="fas fa-plus-circle" v-else></i>
                </div>
              </STableRowItem>
              <STableRowItem :text="chargingDockPortItem.number" />

              <STableRowItem>
                <router-link
                  v-if="chargingDockPortItem.bike"
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: chargingDockPortItem.bike.id },
                  }"
                  :class="`text-blue-600 mr-5`"
                  target="_blank"
                >
                  {{ chargingDockPortItem.bike.qr_code }}
                </router-link>
                <span v-else>--</span>
              </STableRowItem>

              <STableRowItem>
                <x-status
                  :variant="getPortStatusVariant(chargingDockPortItem.status)"
                  :text="getPortStatusText(chargingDockPortItem.status)"
                  profile="vehicle"
                />
              </STableRowItem>
            </STableRow>
            <STableRow
              v-if="opened.includes(chargingDockPortItemIndex)"
              :key="chargingDockPortItemIndex"
            >
              <td colspan="10">
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{ $t('components.chargingDockPort.table.columns.imei') }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      chargingDockPortItem.bike
                        ? chargingDockPortItem.bike.lock.lock_id
                        : '--'
                    }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.chargingDockPort.table.columns.adapterCode'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      chargingDockPortItem.bike
                        ? chargingDockPortItem.bike.charging_adapter
                          ? chargingDockPortItem.bike.charging_adapter
                              .adapter_code
                          : '--'
                        : '--'
                    }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.chargingDockPort.table.columns.lastUpdated'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      getFormattedDateTime(
                        chargingDockPortItem.updated,
                        'D MMM YYYY, h:mm:ss a'
                      ) | '--'
                    }}
                  </div>
                </div>
              </td>
            </STableRow>
          </template>
        </template></STable
      >
    </div>
  </slide-over-right>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { ChargingDockPortIndexConfig } from '@/config/ChargingDockPortIndexConfig'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'ChargingDockPortIndex',
  components: {
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    STable,
    STableRow,
    STableRowItem,
    XStatus,
    SlideOverRightTrigger: () =>
      import('@/components/modals/SlideOverRightTrigger'),
  },
  data() {
    return {
      isLoading: false,
      windowWidth: window.innerWidth,
      opened: [],
      counter: 0,
      dockData: {},
      dockPortsData: [],
      sorId: ChargingDockPortIndexConfig.events.sorId,
      tableHeaders: [
        {
          text: this.$t('components.chargingDockPort.table.columns.portNumber'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.chargingDockPort.table.columns.lastUpdated'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDockPort.table.columns.vehicleQr'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDockPort.table.columns.imei'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.chargingDockPort.table.columns.adapterCode'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.chargingDockPort.table.columns.dockedStatus'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.chargingDockPort.table.columns.portNumber'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDockPort.table.columns.vehicleQr'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.chargingDockPort.table.columns.dockedStatus'
          ),
          width: '30%',
          sort: null,
        },
      ],
      chargingDockPortList: [],
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    EventBus.$on(ChargingDockPortIndexConfig.events.editingData, (item) => {
      this.dockData = item
      this.chargingDockPortList = []
      this.fetchChargingPortList(item.id)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    showModal() {
      dispatchEvent(new Event(ChargingDockPortIndexConfig.events.sorToggle))
    },
    async fetchChargingPortList(dockId) {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.vehicle.chargingDock.portIndex(dockId))
        .then((res) => {
          this.chargingDockPortList = res.data.data
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to load port list',
          })
          console.log('port-list-err', err.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPortStatusText(status) {
      if (status === 'unavailable') {
        return 'Unavailable'
      } else if (status === 'available') {
        return 'Available'
      }
      return status
    },
    getPortStatusVariant(status) {
      if (status === 'unavailable') {
        return 'gray'
      } else if (status === 'available') {
        return 'green'
      }
      return 'gray'
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
