<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus
          :title="$t('components.chargingDock.table.headline')"
          :hidePlus="true"
          class="mb-10"
        />
      </div>

      <FSTable
        :fst-id="`charging-dock`"
        :endpoint="indexDataEndpoint"
        :headers="getTableHeaders"
        :isSyncEnabled="true"
      >
        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem>
                  <span
                    v-tooltip="item.kuhmute_hub_uuid"
                    class="text-blue-600 cursor-pointer"
                    @click="onShowChargingDockPorts(item)"
                  >
                    {{ $truncate(item.kuhmute_hub_uuid, -12) }}
                  </span>
                </FSTableRowItem>
                <FSTableRowItem :text="item.name" :text-fallback="`--`" />
                <FSTableRowItem :text="item.address" :text-fallback="`--`" />
                <FSTableRowItem
                  :text="item.total_ports"
                  :text-fallback="`--`"
                />
                <FSTableRowItem
                  :text="item.available_ports"
                  :text-fallback="`--`"
                />
                <FSTableRowItem
                  :text="item.unavailable_ports"
                  :text-fallback="`--`"
                />
                <FSTableRowItem>
                  <x-status
                    :variant="item.status === 'Available' ? 'green' : 'gray'"
                    :text="item.status"
                    profile="vehicle"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="flex items-center">
                    <!-- <OtoEditIcon @click="onOpenES({ primaryKey: item.id })" /> -->
                    <MoreActions
                      :data="item"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <span
                    v-tooltip="item.kuhmute_hub_uuid"
                    class="text-blue-600 cursor-pointer"
                    @click="onShowChargingDockPorts(item)"
                  >
                    {{ $truncate(item.kuhmute_hub_uuid, -12) }}
                  </span>
                </FSTableRowItem>
                <FSTableRowItem :text="item.name" :text-fallback="`--`" />
                <FSTableRowItem>
                  <x-status
                    :variant="item.status === 'Available' ? 'green' : 'gray'"
                    :text="item.status"
                    profile="vehicle"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.chargingDock.table.columns.totalPorts')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.total_ports | '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.chargingDock.table.columns.availablePorts'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.available_ports | '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.chargingDock.table.columns.unavailablePorts'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.unavailable_ports | '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.chargingDock.table.columns.address') }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.address | '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.chargingDock.table.columns.action') }}
                    </div>
                    <div class="col-span-5 right-text">
                      <MoreActions
                        :data="item"
                        @refresh="$store.dispatch('fsTable/fetchData')"
                      />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
      <ChargingDockPortIndex />
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
// import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActions from '@/views/charging-dock/MoreActions.vue'
import TitlePlus from '@/components/ui/TitlePlus'
import XStatus from '@/components/badge/XStatus'
import { getFormattedDateTime } from '@/utils/datetime'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { ChargingDockPortIndexConfig } from '@/config/ChargingDockPortIndexConfig'

import ChargingDockPortIndex from '@/views/charging-dock/ChargingDockPortIndex.vue'

import { EventBus } from '@/utils'
export default {
  name: 'ChargingDockIndex',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    // OtoEditIcon,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    // OperationPrimerAddEdit,
    MoreActions,
    XStatus,
    ChargingDockPortIndex,
  },

  data() {
    return {
      indexDataEndpoint: useEndpoints.vehicle.chargingDock.index(),
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t('components.chargingDock.table.columns.kuhmuteUUID'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.name'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.address'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.totalPorts'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.availablePorts'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.chargingDock.table.columns.unavailablePorts'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.status'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.action'),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.chargingDock.table.columns.kuhmuteUUID'),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.name'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t('components.chargingDock.table.columns.status'),
          width: '30%',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },

  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    onShowChargingDockPorts(data) {
      EventBus.$emit(ChargingDockPortIndexConfig.events.editingData, data)
      dispatchEvent(new Event(ChargingDockPortIndexConfig.events.sorToggle))
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
