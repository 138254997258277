<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :exitButton="{ text: 'Cancel', hidden: false }"
    :size-class="`w-full md:w-2/5`"
  >
    <template #header>
      <div class="flex justify-between -mt-8">
        <div class="panel-title">{{ actionText }}</div>
      </div>
    </template>

    <template #footer>
      <AppButton
        v-if="!isShowBulkTaskReport"
        variant="primary"
        :text="actionText"
        :is-loading="isReq"
        @click="$refs.submitButton.click()"
      />
      <div v-else>
        <AppButton
          variant="primary"
          :text="`Export`"
          @click="handleBulkTaskReportExport"
        />
      </div>
    </template>

    <template #default>
      <template v-if="!isShowBulkTaskReport">
        <ValidationObserver
          tag="div"
          class="px-4"
          v-slot="{ handleSubmit }"
          ref="observer"
        >
          <AppInput
            type="text"
            :name="
              $t('components.taskManagement.addEdit.steps.profile.title.title')
            "
            :label="
              $t('components.taskManagement.addEdit.steps.profile.title.title')
            "
            rules="required"
            :placeholder="
              $t(
                'components.taskManagement.addEdit.steps.profile.placeholder.taskTitle'
              )
            "
            v-model="form.title"
          />

          <AppInput
            v-model="form.user"
            type="richselect"
            :name="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.assignTo'
              )
            "
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.assignTo'
              )
            "
            rules="required"
            :placeholder="
              $t(
                'components.taskManagement.addEdit.steps.profile.placeholder.selectEmployee'
              )
            "
            text-attribute="text"
            value-attribute="value"
            :options="users"
          />

          <AppInput
            type="richselect"
            :name="
              $t('components.taskManagement.addEdit.steps.profile.title.type')
            "
            :label="
              $t('components.taskManagement.addEdit.steps.profile.title.type')
            "
            rules="required"
            :placeholder="
              $t(
                'components.taskManagement.addEdit.steps.profile.placeholder.selectAType'
              )
            "
            :options="[
              {
                value: 'CHARGING',
                text: $t(
                  'components.taskManagement.addEdit.steps.profile.options.taskType.charge'
                ),
              },
              {
                value: 'REBALANCING',
                text: $t(
                  'components.taskManagement.addEdit.steps.profile.options.taskType.rebalance'
                ),
              },
              {
                value: 'SWAP_BATTERY',
                text: $t(
                  'components.taskManagement.addEdit.steps.profile.options.taskType.swapBattery'
                ),
              },
              {
                value: 'MAINTENANCE',
                text: $t(
                  'components.taskManagement.addEdit.steps.profile.options.taskType.maintenance'
                ),
              },
            ]"
            hide-search-box
            v-model="form.task_type"
          />

          <AppInput
            type="textarea"
            :name="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.description'
              )
            "
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.description'
              )
            "
            rules=""
            :placeholder="
              $t(
                'components.taskManagement.addEdit.steps.profile.placeholder.writeDescription'
              )
            "
            v-model="form.description"
          />

          <div class="grid grid-cols-2 gap-2">
            <TaskAddEditStatusDropdown
              v-model="form.task_status"
              :label="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.status'
                )
              "
              :options="taskStatusOptions"
              :disabled="getTaskStatusDisabled"
            />

            <TaskAddEditStatusDropdown
              v-model="form.priority"
              :label="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.priority'
                )
              "
              :options="taskPriorityOptions"
              :disabled="getTaskPriorityDisabled"
            />
          </div>
          <AppInput
            type="date"
            :name="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.dueDate'
              )
            "
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.dueDate'
              )
            "
            rules="required"
            v-model="form.due_by"
          />

          <template v-if="!isExternalTaskCreation">
            <AppInput
              v-if="isEditing"
              v-model="form.vehicle"
              type="richselect"
              :name="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
                )
              "
              :label="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
                )
              "
              rules="required"
              placeholder="e.g. 10001001"
              text-attribute="text"
              value-attribute="value"
              :disabled="true"
              :options="vehicleOptions"
            />
            <AppInput
              v-else
              v-model="form.vehicle"
              type="richselect"
              :name="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
                )
              "
              :label="
                $t(
                  'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
                )
              "
              rules="required"
              placeholder="e.g. 10001001"
              text-attribute="text"
              value-attribute="value"
              multiple
              :options="vehicleOptions"
              :fetch-options="onFetchVehicles"
            />
          </template>

          <button
            ref="submitButton"
            type="submit"
            class="hidden"
            @click="handleSubmit(onSave)"
          >
            Save
          </button>
        </ValidationObserver>
      </template>
      <template v-else>
        <div
          :key="`task-bulk-report`"
          class=" mt-3 pt-5 border-oLightestGray border-t-2"
        >
          <STable :sId="'task-bulk-report'" :headers="taskBulkReportHeaders">
            <template
              v-for="(taskReportItem, taskReportItemIndex) in taskBulkReports"
            >
              <STableRow :key="taskReportItemIndex" text-fallback-always>
                <STableRowItem
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: taskReportItem.id },
                  }"
                  target="_blank"
                  :text="taskReportItem.qr_code"
                />

                <STableRowItem>
                  <template v-if="taskReportItem.success">
                    <i class=" text-green-500 fas fa-check-circle" />
                  </template>
                  <template v-else>
                    <i class=" text-oRed fas fa-exclamation-circle" />
                  </template>
                </STableRowItem>
                <STableRowItem
                  :text="taskReportItem.failure_reason"
                /> </STableRow></template
          ></STable>
        </div>
      </template>
    </template>
  </EdgeStack>
</template>
<script>
import { EdgeStack } from '@/components/modals'

import TaskAddEditStatusDropdown from './AddEditStatusDropdown.vue'
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'
import dayjs from 'dayjs'
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import {
  taskStatusOptions,
  taskPriorityOptions,
  getTaskStatusOptionsByStatus,
} from '.'
import { exportHeaderGroup, getTransformRowData } from '@/utils/export-data'
import { saveAs } from 'file-saver'
const taskAddEditFormModel = (isEditing = false) => ({
  user: '',
  vehicle: '',

  title: '',
  description: '',

  priority: isEditing ? '' : 'H',
  task_type: '',
  task_status: isEditing ? '' : 'TODO',

  due_by: isEditing
    ? ''
    : dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
})
export default {
  name: 'TaskAddEdit',

  components: {
    EdgeStack,
    TaskAddEditStatusDropdown,
    STable,
    STableRow,
    STableRowItem,
  },

  props: {
    esId: {
      type: String,
      default: 'task-add-edit',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      required: false,
    },
    formData: {
      type: Object,
      required: false,
    },
    vehicleOptions: {
      type: Array,
      required: false,
    },
    isExternalTaskCreation: {
      type: Boolean,
      default: false,
    },
    externalSource: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: taskAddEditFormModel(this.isEditing),
      users: [],
      isReq: false,
      isBulkTaskAttempt: false,
      isBulkTaskAttemptSuccess: false,

      taskStatusOptions: taskStatusOptions,
      taskPriorityOptions: taskPriorityOptions,
      taskBulkReportHeaders: [
        {
          text: 'Qr Code',
          width: '20%',
          sort: null,
        },
        {
          text: 'Status',
          width: '10%',
          sort: null,
        },
        {
          text: 'Failed Reason',
          width: '70%',
          sort: null,
        },
      ],
      taskBulkReports: [],
    }
  },
  mounted() {
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('toggle', this.esId),
      () => {
        this.isBulkTaskAttempt = false
        this.isBulkTaskAttemptSuccess = false
        this.taskBulkReports = []
        this.form = taskAddEditFormModel()
      }
    )
  },

  computed: {
    isEditing() {
      return !!this.formData
    },
    isShowBulkTaskReport() {
      return (
        !this.isEditing &&
        this.isBulkTaskAttempt &&
        this.isBulkTaskAttemptSuccess
      )
    },
    actionText() {
      if (this.isShowBulkTaskReport) {
        return 'Report'
      } else {
        return this.isEditing
          ? this.$t('components.taskManagement.addEdit.headline.update')
          : this.$t('components.taskManagement.addEdit.headline.add')
      }
    },
    getTaskStatusOptions() {
      if (this.isEditing) return this.taskStatusOptions

      return getTaskStatusOptionsByStatus(
        this.form.task_status,
        this.taskStatusOptions
      )
    },
    getTaskStatusDisabled() {
      return (
        this.isEditing &&
        ['DROPPED', 'CANCELLED'].includes(this.form.task_status)
      )
    },
    getTaskPriorityDisabled() {
      return (
        this.isEditing &&
        ['DROPPED', 'CANCELLED'].includes(this.form.task_status)
      )
    },
  },

  async created() {
    await this.fetchUsers()
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        console.log('formData', data)

        if (data) {
          this.form = { ...data }
        } else {
          this.form = taskAddEditFormModel()
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // todo: should confirm before leaving
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    async fetchUsers() {
      await this.$http
        .get('/dashboard/operators/?dropdown=true')
        .then(({ data }) => {
          this.users = data?.data.map((user) => ({
            value: user.id,
            text: user.full_name,
          }))
          // console.log('fetchUsers', this.users)
        })
        .catch((err) => {
          console.log('fetchUsersErr', err, err.response)
        })
    },

    async onFetchUsers(query) {
      await this.$http
        .get('/dashboard/operators/', {
          params: {
            search: query,
          },
        })
        .then(({ data }) => {
          this.users = data?.data.map((user) => ({
            value: user.id,
            text: user.full_name,
          }))
          // console.log('onFetchUsers', this.users)
        })
        .catch((err) => {
          console.log('onFetchUsersErr', err, err.response)
        })

      return { results: this.users }
    },

    async onFetchVehicles(query) {
      await this.$http
        .get('/dashboard/vehicles/', {
          params: {
            search: query,
            task_status: 'not_in_task',
          },
        })
        .then(({ data }) => {
          this.vehicles = data?.data.map((vehicle) => ({
            text: vehicle.qr_code,
            value: vehicle.id,
          }))
          // console.log('onFetchUsers', this.users)
        })
        .catch((err) => {
          console.log('onFetchUsersErr', err, err.response)
        })

      return { results: this.vehicles }
    },

    async onSave() {
      if (this.busy) return

      this.isReq = true
      const url = this.isEditing
        ? useEndpoints.task.update(this.primaryKey)
        : useEndpoints.task.create()

      const method = this.isEditing ? 'PATCH' : 'POST'
      if (this.isExternalTaskCreation) {
        if (this.externalSource === 'vehicle-list') {
          const selectedVehicles = this.$store.getters[
            'fsTable/getData'
          ].filter((v) => v.isSelected)
          if (selectedVehicles.length > 0) {
            this.form.vehicle = selectedVehicles.map((v) => v.id)
          }
        }
        if (this.externalSource === 'live-map') {
          Object.values(this.$store.getters['liveMap/getResData']).filter(
            (item) => item.isSelected
          )

          const selectedVehicles = Object.values(
            this.$store.getters['liveMap/getResData']
          ).filter((item) => item.isSelected)
          if (selectedVehicles.length > 0) {
            this.form.vehicle = selectedVehicles.map((v) => v.id)
          }
        }
      }
      const formDataProxy = { ...this.form }

      formDataProxy.due_by = new Date(formDataProxy.due_by).toISOString()

      if (Array.isArray(formDataProxy.vehicle)) {
        delete formDataProxy.vehicle
      }
      const data = new xMan(formDataProxy).toFormData()
      if (Array.isArray(this.form.vehicle)) {
        this.isBulkTaskAttempt = true
        this.form.vehicle.forEach((id) => data.append('vehicle', id))
      }
      this.$http({
        method,
        url,
        data,
      })
        .then(({ data }) => {
          const verb = this.isEditing ? 'updated' : 'created'
          this.$emit('save', data)

          if (this.isBulkTaskAttempt) {
            this.isBulkTaskAttemptSuccess = true
            this.taskBulkReports = data.data
          } else {
            this.$edgeStack.close(this.esId)
            this.$edgeStack.emitter.on(
              this.$edgeStack.getEventName('closed', this.esId),
              () => {
                this.form = taskAddEditFormModel()
              }
            )

            this.$notify(
              {
                group: 'bottomLeft',
                type: 'success',
                title: `Task has been ${verb}`,
                text: `The task has been successfully ${verb}`,
              },
              5000
            )
          }
        })
        .catch((err) => {
          console.log('onSaveErr', err, err.response)
          this.$notify(
            {
              group: 'bottomLeft',
              type: 'error',
              title: `Error occured [${err?.response?.status}]`,
              text:
                err.response?.data?.detail ??
                `Failed to create task, please try agian.`,
            },
            5000
          )
        })
        .finally(() => (this.isReq = false))
    },
    csvMaker(data) {
      const csvRows = []

      const headers = exportHeaderGroup['bulkTaskReport'].map((x) => x.title)

      csvRows.push(headers.join(','))
      for (let i = 0; i < data.length; i++) {
        const values = Object.values(
          getTransformRowData(data[i], 'bulkTaskReport')
        ).join(',')
        csvRows.push(values)
      }

      return csvRows.join('\n')
    },
    handleBulkTaskReportExport() {
      const blob = new Blob([this.csvMaker(this.taskBulkReports)], {
        type: 'text/csv',
      })
      const fileName = `${this.form.task_type}_bulk_task-${this.form.due_by}.csv`
      saveAs(blob, fileName)
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}
</style>
