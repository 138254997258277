var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative mt-2 bg-white rounded shadow"},[(!_vm.isLoaded)?_c('section',{staticClass:"h-96"},[_c('loading',{attrs:{"active":!_vm.isLoaded,"is-full-page":false}})],1):(_vm.isLoaded || _vm.lockConfig)?_c('content-section',[_c('oto-typography',{attrs:{"text":_vm.$t(
          'components.vehicleDetailsManagement.diveIntoTab.hardware.details.basicInfo.title'
        ),"variant":"one"}}),_c('div',{staticClass:"grid grid-cols-1 mt-5 md:grid-cols-2 md:gap-5"},[(_vm.lockConfig.has_firmware_version_updates)?_c('div',{staticClass:"flex items-center"},[_c('text-input',{attrs:{"rules":"","label":_vm.isOkaiIOT
              ? 'Protocol version'
              : _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.details.basicInfo.firmwareVersion'
                ),"value":_vm.vehicle.scooter_extras.firmware_version,"disabled":true,"placeholder":!_vm.vehicle.scooter_extras.firmware_version
              ? '+ACK:GTHBD - heartbeat packet not found'
              : ''}})],1):_vm._e(),(_vm.isOkaiIOT)?_c('div',{staticClass:"flex items-center"},[_c('text-input',{attrs:{"rules":"","label":"Command","placeholder":"e.g. +ATCK=GTRXX"},model:{value:(_vm.hexCommand),callback:function ($$v) {_vm.hexCommand=$$v},expression:"hexCommand"}}),_c('div',{staticClass:"flex items-center w-3/12"},[_c('anchor-button',{staticClass:"pl-3 cursor-pointer",attrs:{"text":'Send',"variant":"success-alt"},on:{"click":_vm.handleSendCommand}})],1)],1):_vm._e()]),_c('oto-typography',{staticClass:"inline-block mt-10",attrs:{"text":_vm.$t(
          'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.title'
        ),"variant":"one"}}),_c('div',{staticClass:"max-w-4xl"},[_c('div',{staticClass:"grid grid-cols-1 gap-8 mt-5 md:grid-cols-2"},[(_vm.lockConfig.has_headlight_control)?[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.headlight.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.headlight.subTitle'
              ),"onText":"ON","offText":"OFF","onValue":true,"offValue":false,"size":"lg","readonly":_vm.isEditable},on:{"change":_vm.handleHeadlightUpdate},model:{value:(_vm.vehicle.control_flags.headlight),callback:function ($$v) {_vm.$set(_vm.vehicle.control_flags, "headlight", $$v)},expression:"vehicle.control_flags.headlight"}})]:_vm._e(),(_vm.lockConfig.has_taillight_control)?[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.taillight.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.taillight.subTitle'
              ),"onText":"ON","offText":"OFF","onValue":true,"offValue":false,"size":"lg","readonly":_vm.isEditable},on:{"change":_vm.handleTaillightUpdate},model:{value:(_vm.vehicle.control_flags.taillight),callback:function ($$v) {_vm.$set(_vm.vehicle.control_flags, "taillight", $$v)},expression:"vehicle.control_flags.taillight"}})]:_vm._e(),(_vm.lockConfig.has_throttle_control)?[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.throttle.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.throttle.subTitle'
              ),"onText":"ON","offText":"OFF","onValue":true,"offValue":false,"size":"lg","readonly":_vm.isEditable},on:{"change":_vm.handleThrottleUpdate},model:{value:(_vm.vehicle.control_flags.throttle),callback:function ($$v) {_vm.$set(_vm.vehicle.control_flags, "throttle", $$v)},expression:"vehicle.control_flags.throttle"}})]:_vm._e(),(_vm.lockConfig.has_speed_mode_control)?[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.lockSpeedMode.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.lockSpeedMode.subTitle'
              ),"onText":"ON","offText":"OFF","onValue":true,"offValue":false,"size":"lg","readonly":_vm.isEditable},on:{"change":_vm.handleLockSpeedModeUpdate},model:{value:(_vm.vehicle.control_flags.lock_speed_mode),callback:function ($$v) {_vm.$set(_vm.vehicle.control_flags, "lock_speed_mode", $$v)},expression:"vehicle.control_flags.lock_speed_mode"}})]:_vm._e(),(_vm.lockConfig.has_ring_alarm)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.ring.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.ring.subTitle'
              ),"size":"lg","type":"RING","readonly":_vm.isEditable},on:{"click":_vm.handleAlarmClicked}})]:_vm._e(),(_vm.lockConfig.has_mech_lock)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockCable.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockCable.subTitle'
              ),"size":"lg","type":"UNLOCK_CABLE_LOCK","readonly":_vm.isEditable},on:{"click":_vm.handleCableUnlockClicked}})]:_vm._e(),(_vm.lockConfig.has_unlock_helmet_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockHelmet.title'
              ),"subtitle":("Helmet is now " + (_vm.vehicle.is_helmet_unlocked ? 'unlocked' : 'locked')),"size":"lg","type":"UNLOCK_HELMET","haveSyncButton":true,"isSyncButtonSpinning":_vm.isHelmetStatusSyncing,"readonly":_vm.isEditable},on:{"click":_vm.handleHelmetUnlockClicked,"sync":_vm.handleHelmetStatusSync}})]:_vm._e(),(_vm.lockConfig.has_unlock_battery_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockBattery.title'
              ),"subtitle":("Battery is now " + (_vm.vehicle.status_flags.is_battery_unlocked
                  ? 'unlocked'
                  : 'locked')),"size":"lg","type":"UNLOCK_BATTERY","haveSyncButton":true,"isSyncButtonSpinning":_vm.isBatteryStatusSyncing},on:{"click":_vm.handleBatteryUnlockClicked,"sync":_vm.handleBatteryStatusSync}})]:_vm._e(),(_vm.lockConfig.has_lock_battery_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.lockBattery.title'
              ),"subtitle":("Battery is now " + (_vm.vehicle.status_flags.is_battery_unlocked
                  ? 'unlocked'
                  : 'locked')),"size":"lg","type":"LOCK_BATTERY","haveSyncButton":true,"isSyncButtonSpinning":_vm.isBatteryStatusSyncing},on:{"click":_vm.handleBatteryLockClicked,"sync":_vm.handleBatteryStatusSync}})]:_vm._e(),(_vm.lockConfig.has_saddle_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockSaddle.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockSaddle.subTitle'
              ),"size":"lg","type":"UNLOCK_SADDLE"},on:{"click":_vm.handleSaddleUnlockClicked}})]:_vm._e(),(_vm.lockConfig.has_tailbox_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockTailbox.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.unlockTailbox.subTitle'
              ),"size":"lg","type":"UNLOCK_TAILBOX"},on:{"click":_vm.handleTailboxUnlockClicked}})]:_vm._e(),(_vm.lockConfig.has_restart_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.restart.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.restart.subTitle'
              ),"size":"lg","type":"RESTART"},on:{"click":_vm.handleRestartIotClicked}})]:_vm._e(),(_vm.lockConfig.has_speed_limit_control)?[_c('OptionsTrackbar',{attrs:{"size":"lg","options":[
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.speedMode.low'
                ),
                subtitle: ((_vm.vehicle.scooter_extras
                    ? _vm.vehicle.scooter_extras.low_speed_limit
                    : '--') + " km/hour"),
                speed: 'L',
              },
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.speedMode.medium'
                ),
                subtitle: ((_vm.vehicle.scooter_extras
                    ? _vm.vehicle.scooter_extras.medium_speed_limit
                    : '--') + " km/hour"),
                speed: 'M',
              },
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.speedMode.high'
                ),
                subtitle: ((_vm.vehicle.scooter_extras
                    ? _vm.vehicle.scooter_extras.high_speed_limit
                    : '--') + " km/hour"),
                speed: 'H',
              } ],"title-attribute":"title","subtitle-attribute":"subtitle","value-attribute":"speed","clicked-item-is-loading":_vm.optionsTrackbarIsLoading},on:{"click":_vm.onClickOptionsTrackbar},model:{value:(_vm.vehicle.scooter_extras.speed),callback:function ($$v) {_vm.$set(_vm.vehicle.scooter_extras, "speed", $$v)},expression:"vehicle.scooter_extras.speed"}})]:_vm._e(),(_vm.isOmniIOT)?[_c('OptionsTrackbar',{attrs:{"size":"lg","options":[
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.startMode.kickStartOn'
                ),
                mode: 'NZ',
              },
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.startMode.kickStartOff'
                ),
                mode: 'Z',
              } ],"title-attribute":"title","value-attribute":"mode","clicked-item-is-loading":_vm.optionsTrackbarVehicleStartModeIsLoading},on:{"click":_vm.onClickVehicleStartModeChange},model:{value:(_vm.vehicle.scooter_extras.start_mode),callback:function ($$v) {_vm.$set(_vm.vehicle.scooter_extras, "start_mode", $$v)},expression:"vehicle.scooter_extras.start_mode"}})]:_vm._e(),(_vm.vehicle.is_iot_mode_changeable)?[_c('OptionsTrackbar',{attrs:{"size":"lg","options":[
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.iotMode.live'
                ),
                subtitle: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.iotMode.subTitle'
                ),
                iot_mode: 'LIVE',
              },
              {
                title: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.iotMode.test'
                ),
                subtitle: _vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.iotMode.subTitle'
                ),
                iot_mode: 'TEST',
              } ],"title-attribute":"title","subtitle-attribute":"subtitle","value-attribute":"iot_mode","clicked-item-is-loading":_vm.optionsTrackbarVehicleModeIsLoading},on:{"click":_vm.onClickVehicleModeChange},model:{value:(_vm.vehicle.iot_mode),callback:function ($$v) {_vm.$set(_vm.vehicle, "iot_mode", $$v)},expression:"vehicle.iot_mode"}})]:_vm._e(),(_vm.lockConfig.has_teltonika_tailbox_control)?[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.teltonikaTailbox.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.teltonikaTailbox.subTitle'
              ),"onText":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.teltonikaTailbox.lock'
              ),"offText":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.teltonikaTailbox.unlock'
              ),"onValue":true,"offValue":false,"size":"lg"},on:{"change":_vm.handleTeltonikaTailboxMode},model:{value:(_vm.lock.teltonika_tail_box_is_locked),callback:function ($$v) {_vm.$set(_vm.lock, "teltonika_tail_box_is_locked", $$v)},expression:"lock.teltonika_tail_box_is_locked"}})]:_vm._e(),(_vm.lockConfig.has_open_seat_control)?[_c('alarm-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.openSeat.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.openSeat.subTitle'
              ),"size":"lg","type":"UNLOCK_SEAT"},on:{"click":_vm.handleSeatUnlockClicked}})]:_vm._e()],2)]),_c('div',{staticClass:"max-w-4xl mt-16"},[_c('div',{staticClass:"grid grid-cols-1 mt-5 md:grid-cols-3 md:gap-10"},[_c('text-input',{attrs:{"label":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.locationFrequency.onRide'
            ),"disabled":true},model:{value:(_vm.getOnRideLocationUpdateFrequency),callback:function ($$v) {_vm.getOnRideLocationUpdateFrequency=$$v},expression:"getOnRideLocationUpdateFrequency"}}),_c('text-input',{attrs:{"label":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.locationFrequency.offRide'
            ),"disabled":true},model:{value:(_vm.getOffRideLocationUpdateFrequency),callback:function ($$v) {_vm.getOffRideLocationUpdateFrequency=$$v},expression:"getOffRideLocationUpdateFrequency"}}),_c('div',{staticClass:"flex items-center w-3/12"},[_c('anchor-button',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.locationFrequency.sync'
              ),"variant":"success-alt"},on:{"click":_vm.handleHeartbeatSync}})],1)],1)]),(_vm.isSegwayIOT)?_c('div',{staticClass:"max-w-4xl mt-16"},[_c('div',{staticClass:"w-full"},[_c('oto-typography',{attrs:{"text":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.hardware.controllerInfo.maxSpeedLimitForSpeedMode.title'
            ),"variant":"one"}}),_c('div',{key:("speedlimit_" + _vm.counter),staticClass:" mt-3 pt-5 border-t-2"},[_vm._l((_vm.speedLimitSettings),function(item,itemIndex){return [_c('SmartAppInput',{key:itemIndex,attrs:{"rowId":("speedlimit_" + itemIndex),"type":item.type,"label":item.label,"placeholder":item.placeholder,"options":item.options,"isEdit":item.isEdit,"isSearchBox":item.isSearchBox},on:{"save":function($event){return _vm.onSetLimit(
                  ("" + (item.mode)),
                  ("" + (_vm.vehicle['scooter_extras'][("" + (item.variable))]))
                )}},model:{value:(_vm.vehicle['scooter_extras'][("" + (item.variable))]),callback:function ($$v) {_vm.$set(_vm.vehicle['scooter_extras'], ("" + (item.variable)), $$v)},expression:"vehicle['scooter_extras'][`${item.variable}`]"}})]})],2)],1)]):_vm._e(),_c('div',{staticClass:" mt-16"},[_c('div',{staticClass:"w-full"},[_c('oto-typography',{attrs:{"text":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.title'
            ),"variant":"one"}}),_c('div',{staticClass:" mt-3 pt-5 border-t-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"},[_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.helmetAttached.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.helmetAttached.subTitle'
              ),"onText":"YES","offText":"NO","onValue":true,"offValue":false,"size":"lg","readonly":false},on:{"change":function (e) { return _vm.handleExternalHardware('has_helmet_attached', e); }},model:{value:(_vm.vehicle.scooter_extras.has_helmet_attached),callback:function ($$v) {_vm.$set(_vm.vehicle.scooter_extras, "has_helmet_attached", $$v)},expression:"vehicle.scooter_extras.has_helmet_attached"}}),_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.aiToolboxAttached.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.aiToolboxAttached.subTitle'
              ),"onText":"YES","offText":"NO","onValue":true,"offValue":false,"size":"lg","readonly":false},on:{"change":function (e) { return _vm.handleExternalHardware('has_ai_toolbox_attached', e); }},model:{value:(_vm.vehicle.scooter_extras.has_ai_toolbox_attached),callback:function ($$v) {_vm.$set(_vm.vehicle.scooter_extras, "has_ai_toolbox_attached", $$v)},expression:"vehicle.scooter_extras.has_ai_toolbox_attached"}}),_c('toggle-box',{attrs:{"title":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.chargingDocAttached.title'
              ),"subtitle":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.hardware.externalHardware.chargingDocAttached.subTitle'
              ),"onText":"YES","offText":"NO","onValue":true,"offValue":false,"size":"lg","readonly":false},on:{"change":function (e) { return _vm.handleExternalHardware('has_charging_doc_attached', e); }},model:{value:(_vm.vehicle.scooter_extras.has_charging_doc_attached),callback:function ($$v) {_vm.$set(_vm.vehicle.scooter_extras, "has_charging_doc_attached", $$v)},expression:"vehicle.scooter_extras.has_charging_doc_attached"}})],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }