import {
  getHumanReadableDuration,
  getFormattedDateTime,
  getUTCAwareTime,
} from '@/utils/datetime'
import { getFeedbackStatus, getVehicleTypeAliasFromData } from './helper'
import {
  getUserTripStatus,
  getRiderTripStatus,
  getPaymentForText,
} from '@/resources/SmartSearchResource.js'
export const getTransformRowData = (rawData, type, orgCurrencySymbol) => {
  if (type === 'trip') {
    return {
      id: rawData.id.substr(rawData.id.length - 5),
      rider: rawData.user.full_name || '--',
      phone_number: rawData.user.phone_number || '--',
      vehicle_qr: rawData?.bike?.qr_code || '--',
      pickup_time: getFormattedDateTime(
        rawData.pick_up_time,
        'D MMM, YY hh:mm:ss a'
      ),
      pickup_location: rawData.pick_up_location || '--',
      dropoff_location: rawData.drop_off_location || '--',
      dropoff_time: getFormattedDateTime(
        rawData.drop_off_time,
        'D MMM, YY hh:mm:ss a'
      ),
      duration: getHumanReadableDuration(rawData.riding_time),
      currency: `${rawData.invoice.currency.name || '--'}`,
      charge: `${rawData.invoice.currency.symbol || '--'} ${rawData.invoice
        .amount || '--'}`,
      group_trip: rawData.child_trips.length > 0 ? 'YES' : 'NO',
      feedback: `${getFeedbackStatus(rawData.received_good_feedback)}`,
      illegally_parked: rawData.vehicle_was_legally_parked ? 'NO' : 'YES',
      went_outside_geofence: rawData.went_outside_geofence ? 'YES' : 'NO',
      went_inside_restricted_area: rawData.went_inside_restricted_area
        ? 'YES'
        : 'NO',
      went_inside_slowzone_area: rawData.went_inside_slowzone_area
        ? 'YES'
        : 'NO',
      was_paused: rawData.was_paused ? 'YES' : 'NO',
      voucher_applied: rawData.voucher_applied ? 'YES' : 'NO',
      promo_applied: rawData.promo_applied ? 'YES' : 'NO',
      pass_applied: rawData.pass_applied ? 'YES' : 'NO',
      rider_balance_negative: rawData.rider_balance_negative ? 'YES' : 'NO',
      balance_refund:
        rawData.invoice && rawData.invoice.is_refunded ? 'YES' : 'NO',
      pre_authorized: '--',
      status: `${getUserTripStatus(
        rawData.status,
        rawData.status_forced,
        rawData.status_forced_by_system
      )}`,
    }
  }
  if (type === 'rider') {
    return {
      id: rawData.id.substr(rawData.id.length - 5),
      name: rawData.full_name || '--',
      phone_number: rawData.phone_number || '--',
      joining_date:
        getFormattedDateTime(rawData.joining_date, 'D MMM, YY hh:mm:ss a') ||
        '--',
      last_login:
        getFormattedDateTime(rawData.last_login, 'D MMM, YY hh:mm:ss a') ||
        '--',
      last_trip_status: getRiderTripStatus(rawData.last_trip_status),
      default_card: rawData.default_card
        ? `${rawData.default_card.brand}**${rawData.default_card.number}`
        : '--',
      device: rawData.app_device_model || '--',
      document_verification: rawData.is_doc_verified ? 'YES' : 'NO',
      currency: '--',
      wallet_balance: `${orgCurrencySymbol} ${parseFloat(
        rawData.balance
      ).toFixed(2)}`,
    }
  }
  if (type === 'vehicle') {
    return {
      id: rawData.id.substr(rawData.id.length - 5),
      name: rawData.name,
      vehicle_type: getVehicleTypeAliasFromData(rawData.bike_category),
      battery: rawData.lock.power_level,
      imei: rawData.lock.lock_id,
      qr_code: rawData.qr_code,
      last_connected: getUTCAwareTime(rawData.last_connected_at),
      last_located: getUTCAwareTime(rawData.last_loc_updated_at),
      last_location: JSON.stringify({
        lat: rawData.location.split(',')[0],
        lng: rawData.location.split(',')[1],
      }),

      charging_pick: rawData.operational_flags.charging_pick ? 'YES' : 'NO',
      maintenance: rawData.operational_flags.maintenance ? 'YES' : 'NO',
      rebalance: rawData.operational_flags.rebalance ? 'YES' : 'NO',
      swap_battery: rawData.operational_flags.swap_battery ? 'YES' : 'NO',
      hibernate_mode: rawData.general_flags.hibernate_mode ? 'YES' : 'NO',
      geo_fence_alert: rawData.general_flags.geo_fence_alert ? 'YES' : 'NO',
      restricted_alert: rawData.general_flags.restricted_alert ? 'YES' : 'NO',
      slowzone_alert: rawData.general_flags.slowzone_alert ? 'YES' : 'NO',
      iot_fault: rawData.general_flags.iot_fault ? 'YES' : 'NO',
      low_battery: rawData.general_flags.low_battery ? 'YES' : 'NO',
      is_charging: rawData.status_flags.is_charging ? 'YES' : 'NO',
      is_parking: rawData.status_flags.is_parking ? 'YES' : 'NO',
      is_reserved: rawData.status_flags.is_reserved ? 'YES' : 'NO',
      is_on_ride: rawData.status_flags.is_on_ride ? 'YES' : 'NO',
      is_idle: rawData.status_flags.is_idle ? 'YES' : 'NO',
      heartbeat: rawData.heart_beat ? 'Active' : 'Inactive',
      lock_status: rawData.lock.is_locked ? 'Locked' : 'Unlocked',
    }
  }
  if (type === 'payment') {
    return {
      invoice_id: rawData.id.substr(rawData.id.length - 5),
      payer_name: rawData.user.full_name,
      transaction_time:
        getFormattedDateTime(rawData.date, 'D MMM, YY hh:mm:ss a') || '--',
      payment_method: rawData.payment_method_details
        ? `${rawData.payment_method_details.brand}**${rawData.payment_method_details.number}`
        : rawData.payment_method,
      payment_ref: rawData.payment_reference_id
        ? `https://dashboard.stripe.com/payments/${rawData.payment_reference_id}`
        : '--',
      payment_for: getPaymentForText('payment', rawData.payment_for),
      currency: rawData.currency.name,
      amount: `${rawData.currency.symbol} ${rawData.amount}` || '--',
      refund: rawData.finalized_refund_amount
        ? `${rawData.currency.symbol} ${rawData.finalized_refund_amount}`
        : '--',
      status: rawData.is_refunded ? 'Refunded' : 'Refund',
    }
  }
  if (type === 'revenue') {
    return {
      invoice_id: rawData.id.substr(rawData.id.length - 5),
      tax_id: rawData.tax_plan
        ? rawData.tax_plan.substr(rawData.tax_plan.length - 5)
        : '--',
      rider: rawData.user ? rawData.user.full_name : '--',
      payer_role: rawData.user ? rawData.user.role_name : '--',
      transaction_time:
        `${getFormattedDateTime(rawData.date, 'D MMM, YY hh:mm:ss a')}` || '--',
      payment_method: rawData.payment_method_details
        ? `${rawData.payment_method_details.brand}**${rawData.payment_method_details.number}`
        : rawData.payment_method,
      payment_ref: rawData.payment_reference_id
        ? `https://dashboard.stripe.com/payments/${rawData.payment_reference_id}`
        : '--',
      payment_for: getPaymentForText('revenue', rawData.payment_for),
      currency: rawData.currency.name,
      amount: `${rawData.currency.symbol} ${rawData.amount}` || '--',
      status: rawData.is_refunded ? 'Refunded' : 'Refund',
    }
  }
  if (type === 'triggerPayment') {
    return {
      invoice_id: rawData.id.substr(rawData.id.length - 5),
      rider: rawData.user ? rawData.user.full_name : '--',
      credit_card: rawData.payment_method_details
        ? `${rawData.payment_method_details.brand}**${rawData.payment_method_details.number}`
        : '--',
      currency: rawData.currency.name,
      amount: rawData.amount
        ? `${rawData.currency.symbol} ${rawData.amount}`
        : '--',

      retry_count: rawData.checkout.retry_count.toString() || '--',
      payment_ref: rawData.payment_reference_id
        ? `https://dashboard.stripe.com/payments/${rawData.payment_reference_id}`
        : '--',
      last_triggered_at:
        `${getFormattedDateTime(
          rawData.checkout.updated_at,
          'D MMM, YY hh:mm:ss a'
        )}` || '--',
      last_triggered_status: rawData.checkout.checkout_status || '--',
    }
  }
  if (type === 'reports') {
    return {
      report_id: rawData.id.substr(rawData.id.length - 5),
      report_by: rawData.user.full_name || '--',
      vehicle_qr: rawData.bike.qr_code || '--',
      date:
        `${getFormattedDateTime(
          rawData.report_date,
          'D MMM, YY hh:mm:ss a'
        )}` || '--',
      report_category: rawData.report_category,
      phase: rawData.report_moment || '--',
      subject: rawData.subject || '--',
      status: rawData.status || '--',
    }
  }
  if (type === 'bulkTaskReport') {
    return {
      vehicle_id: rawData.id,
      vehicle_qr: rawData.qr_code,
      status: rawData.success ? 'SUCCESS' : 'FAILED',
      created_task_id: rawData.created_task_id || '--',
      failure_reason: rawData.failure_reason || '--',
    }
  }
}
