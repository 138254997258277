<template>
  <div class="icon-wrapper p-1">
    <img v-if="getIcon" :src="getIcon" :class="getIconClass" />
  </div>
</template>

<script>
export default {
  name: 'TripFlagIcon',
  props: {
    name: {
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
  },
  computed: {
    collection() {
      return {
        // paused flag
        was_paused: {
          icon: require('@/assets/icon/pause_ride.svg'),
        },
        // parking flag
        legally_parked: {
          icon: require('@/assets/img/flags/on-parking.svg'),
        },
        illegally_parked: {
          icon: require('@/assets/img/flags/on-illegally-parked.svg'),
        },

        // rating
        good_rated: {
          icon: require('@/assets/icon/good_feedback.svg'),
        },
        bad_rated: {
          icon: require('@/assets/icon/bad_feedback.svg'),
        },

        //geofence alert
        inside_service_area: {
          icon: require('@/assets/img/flags/inside_of_service_area.svg'),
        },
        outside_service_area: {
          icon: require('@/assets/img/flags/outside_of_service_area.svg'),
        },
        restricted_area: {
          icon: require('@/assets/img/flags/restricted_area.svg'),
        },
        slowzone_area: {
          icon: require('@/assets/img/flags/slow_area.svg'),
        },

        //Offer
        voucher_applied: {
          icon: require('@/assets/img/flags/voucher_applied.svg'),
        },
        promo_applied: {
          icon: require('@/assets/img/flags/promo_applied.svg'),
        },
        pass_applied: {
          icon: require('@/assets/img/flags/pass_applied.svg'),
        },

        //payment
        rider_balance_negative: {
          icon: require('@/assets/img/flags/rider_balance_negative.svg'),
        },
        trigger_payment_applied: {
          icon: require('@/assets/img/flags/trigger_payment_applied.svg'),
        },
        trigger_payment_failed: {
          icon: require('@/assets/img/flags/trigger_payment_failed.svg'),
        },
        refunded: {
          icon: require('@/assets/img/flags/refund.svg'),
        },
        unlocked_by_rfid: {
          icon: require('@/assets/img/flags/RFID.png'),
        },
      }
    },

    getIcon() {
      let icon = Object.keys(this.collection).includes(this.name)
        ? this.collection[this.name].icon
        : null
      return icon
    },
    getIconClass() {
      let sizes = ['xs', 'sm', 'base', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'
      return 'icon-' + size
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  object-fit: cover;
  overflow: hidden;

  & .icon-xs {
    width: 16px;
    height: 16px;
  }
  & .icon-sm {
    width: 19px;
    height: 19px;
  }
  & .icon-base {
    width: 24px;
    height: 24px;
  }
  & .icon-lg {
    width: 32px;
    height: 32px;
  }
}
</style>
