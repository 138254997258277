<template>
  <base-layout>
    <add-multiple-scooter
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
    />
    <VehicleIndex
      :fst-id="`mopeds`"
      :page-title="$t('components.vehicleManagement.headline.mopeds')"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
      :tableHeaders="tableHeaders"
      :vehicleType="vehicleTypesMap.MOPED.value"
      @add:multiple="addMultiple"
    />
  </base-layout>
</template>

<script>
import { useEndpoints } from '@/composables'
import { VehicleIndex } from '@/composites/vehicle'
import { vehicleTypes } from '@/utils'
export default {
  name: 'MopedIndex',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    AddMultipleScooter: () => import('@/views/scooter/AddMultipleScooter.vue'),
    VehicleIndex,
  },

  data() {
    return {
      vehicleTypesMap: vehicleTypes,
      tableHeaders: [
        {
          text: this.$t('components.vehicleManagement.table.columns.name'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.battery'),
          width: '10%',
          sort: 'lock__power_level',
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.imei'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.qrCode'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastConnected'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastLocated'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.flags'),
          width: '12%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.heartBeat'),
          width: '8%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastLocation'
          ),
          width: '9%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.actions'),
          width: '5%',
          sort: null,
        },
      ],
      // add-edit
      ae: {
        // EdgeStack component
        esId: 'mulitple-vehicles-add-edit',
        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,
      },
    }
  },

  computed: {
    getEndpoint() {
      return useEndpoints.vehicle.index() + '?bike_category=M&'
    },

    getEndpointOptions() {
      return {
        prepend: '',
        append: '',
      }
    },
  },
  methods: {
    addMultiple() {
      this.$edgeStack.open(this.ae.esId)
    },
  },
}
</script>
